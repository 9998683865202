var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lad-confidence"},[_c('el-container',[_c('el-main',[_c('h2',{staticClass:"lad-confidence--title"},[_vm._v(_vm._s(_vm.$t(`${_vm.base}.title`)))]),_vm._v(" "),_c('LazyLoading',{key:`confidence`,attrs:{"loaded":_vm.lazy.confidence},on:{"loaded":(e) => (_vm.lazy.confidence = e)}},[(_vm.lazy.confidence)?_c('Swiper',{ref:"confidenceSwiper",attrs:{"options":_vm.swiperOption}},[_vm._l((_vm.brands),function(brand,index){return _c('SwiperSlide',{key:index},[_c('div',[_c('a',{staticClass:"confidence-image",attrs:{"href":brand.link,"target":"_blank"}},[_c('svg-icon',{staticClass:"sprite-icons",attrs:{"name":brand.item,"width":"10em"}})],1)])])}),_vm._v(" "),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"})],2):_vm._e()],1),_vm._v(" "),_c('h2',{staticClass:"lad-confidence--title"},[_vm._v(_vm._s(_vm.$t(`${_vm.base}.best_cities.subtitle`)))]),_vm._v(" "),_c('LazyHydrate',{attrs:{"when-visible":""}},[_c('el-row',{staticClass:"row-best-cities"},[_c('el-col',{staticClass:"wrapper-best-cities",class:{ 'is-closed': !_vm.openItems }},_vm._l((Object.entries(_vm.cities)),function([region, items]){return _c('div',{key:region},[_c('h3',[_vm._v(_vm._s(_vm.$t(`${_vm.base}.best_cities.region.${region}`)))]),_vm._v(" "),_c('ul',{staticClass:"list-unstyled"},_vm._l((items),function(city){return _c('li',{key:city.slug},[_c('h4',[_c('nuxt-link',{attrs:{"to":{
                        name: 'search-type',
                        params: {
                          type: _vm.$t('router.search_type.type.city'),
                          value: city.slug,
                          tab: _vm.$t('router.search_type.tab.tours'),
                        },
                      }}},[_vm._v("\n                      "+_vm._s(_vm.$t(`${_vm.base}.best_cities.label`, { name: city.name }))+"\n                    ")])],1)])}),0)])}),0),_vm._v(" "),_c('el-col',[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){_vm.openItems = !_vm.openItems}}},[_vm._v(_vm._s(_vm.$t(`${_vm.base}.best_cities.${_vm.itemsText}`)))])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }